<template>
    <div style="height: 100%">

        <div class="pa-4 d-flex justify-end" style="position: fixed; z-index: 1; width: 100%; pointer-events: none;">
            <v-btn
                :color="app.state.style.primary"
                dark depressed
                @click="update"
                v-if="!saved"
                style="pointer-events: auto;"
            >
                {{ $t('Save') }}
            </v-btn>

            <div class="d-flex align-center" style="color: white;" v-else>

                <v-icon left color="green">mdi-check</v-icon>
                {{ $t('Saved') }}
            </div>
        </div>

        <div style="height: 100%">
            <PrismEditor
                class="my-editor"
                v-model="code"
                :highlight="highlighter"
                line-numbers
                @input="saved = false"
            ></PrismEditor>
        </div>

    </div>
</template>

<script>
import { ref, inject, onMounted } from "@vue/composition-api";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";

import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-css";
import "prismjs/themes/prism-tomorrow.min.css";

export default {
    components: {
        PrismEditor,
    },

    setup(props, ctx) {
        
        const app = inject('app');
        const currentWebsite = inject('currentWebsite');

        const code = ref("div {\n\tcolor: red;\n}\n/* SDSD dsds */");
        const saved = ref(true);

        onMounted(() => {

            code.value = currentWebsite.getWebsiteStyle();

        });

        const highlighter = (code) => {
            return highlight(code, languages.css);
        }

        const update = () => {
            const data = {
                website_style: code.value
            }

            currentWebsite.update(data)
            .then(() => {
                app.setSuccess(ctx.root.$t('Updated Styles'));
                saved.value = true;
            })
            .catch((err) => {
                app.setError(err);
            });
        }

        return {
            app,
            code,
            saved,
            highlighter,
            update
        }
    },
};
</script>

<style lang="scss">
// required class
.my-editor {
    background: #2d2d2d;
    color: #ccc;

    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
}

// optional
.prism-editor__textarea:focus {
    outline: none;
}
</style>